import { createAxios, createAxiosTk } from "./axiosHttpMarconi";
export const rmaService = {
  getRmas,
  getRMAById,
  sendEnvioRma,
  finalizarRMAById,
  caducarRevertirRma,
  generateRMAGenerico,
  getRmaDownload,
  getRmaMasivos,
  getClienteRMAGenerico,
};

function getRmaDownload(idRma) {
  const axios = createAxios();
  const cliente = JSON.parse(localStorage.getItem("franquicia"));
  let urlPath = `/cliente/${cliente._id}/rma/masivo/${idRma}/download`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getRmaMasivos(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/rma/masivos`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.date) {
    urlPath += `&fechaInicio=${filters.date[0]}`;
    urlPath += `&fechaFin=${filters.date[1]}`;
  }
  if (filters && filters.order) {
    urlPath += `&sort=createdAt&order=${filters.order}`;
  } else {
    urlPath += `&sort=createdAt&order=descending`;
  }
  if (filters && filters.estados) {
    urlPath += `&estados=${filters.estados}`;
  }
  if (filters && filters.nombreCliente) {
    urlPath += `&nombreCliente=${filters.nombreCliente}`;
  }
  if (filters && filters.filename) {
    urlPath += `&filename=${filters.filename}`;
  }
  return axios.get(urlPath);
}

function getRmas(pagination, body) {
  const axios = createAxios();
  let cliente = JSON.parse(localStorage.getItem("cliente"));
  let urlPath = `/cliente/${cliente._id}/rmas`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?";
  }
  if (body.fechaSolicitud) {
    urlPath += `&dateStart=${body.fechaSolicitud[0]}`;
    urlPath += `&dateEnd=${body.fechaSolicitud[1]}`;
  }
  if (body.codigo) {
    urlPath += `&codigo=${body.codigo}`;
  }
  if (body.clave) {
    urlPath += `&clave=${body.clave}`;
  }
  if (body.remitente) {
    urlPath += `&remitente=${body.remitente}`;
  }
  if (body.fechaCaducidad) {
    urlPath += `&fechaCaducidad=${body.fechaCaducidad}`;
  }
  if (body.referencia) {
    urlPath += `&referencia=${body.referencia}`;
  }
  if (body.estado) {
    urlPath += `&estado=${body.estado}`;
  }
  return axios.get(urlPath, cliente);
}

function getRMAById(id, token, type) {
  const axios = createAxiosTk();
  let urlPath = `/rma/${id}?${type}=${token}`;
  return axios.get(urlPath);
}

function finalizarRMAById(id, token, body, type) {
  const axios = createAxiosTk();
  let urlPath = `/rma/${id}?${type}=${token}`;
  return axios.put(urlPath, body);
}

function sendEnvioRma(body) {
  let cliente = JSON.parse(localStorage.getItem("cliente"));
  const axios = createAxios();
  const urlPath = `cliente/${cliente._id}/rma`;
  return axios.post(urlPath, body);
}

function caducarRevertirRma(idRma, body) {
  const axios = createAxios();
  let cliente = JSON.parse(localStorage.getItem("cliente"));
  let urlPath = `/cliente/${cliente._id}/rma/${idRma}`;
  return axios.put(urlPath, body);
}

function generateRMAGenerico(idCliente, token, type) {
  const axios = createAxiosTk();
  let urlPath = `/cliente/${idCliente}/rma_generico?${type}=${token}`;
  return axios.post(urlPath);
}

function getClienteRMAGenerico(idCliente, token, type) {
  const axios = createAxiosTk();
  let urlPath = `/cliente/${idCliente}/rma_generico?${type}=${token}`;
  return axios.get(urlPath);
}
