<template>
  <div class="row">
    <div class="col-md-9 col-sm-7">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <stats-card
            :title="dashboard.totals.national.toString()"
            subTitle="Nacionales"
          >
            <div slot="header" class="icon-secondary">
              <i class="fa fa-home text-secondary"></i>
            </div>
            <template slot="footer">
              <i class="fa fa-calendar"></i>Mes anterior:
              {{ dashboard.totals.national_last }}
            </template>
          </stats-card>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <stats-card
            :title="dashboard.totals.international.toString()"
            subTitle="Internacionales"
          >
            <div slot="header" class="icon-secondary">
              <i class="fa fa-globe text-secondary"></i>
            </div>
            <template slot="footer">
              <i class="fa fa-calendar"></i>Mes anterior:
              {{ dashboard.totals.international_last }}
            </template>
          </stats-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                Top 10 de ubicaciones de entrega este mes
              </h4>
              <el-skeleton
                v-if="loading"
                :rows="6"
                style="width: 100%; padding: 10px; height: 314px"
              />
              <CitiesPieChart v-else class="p-2" :data="dashboard.cities" />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Top 10 servicios este mes</h4>
              <el-skeleton
                v-if="loading"
                :rows="6"
                style="width: 100%; padding: 10px; height: 314px"
              />
              <ServicesBarChart v-else class="p-2" :data="dashboard.services" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-5">
      <Notifications :data="dashboard.notifications"></Notifications>
    </div>
  </div>
</template>
<script>
import { StatsCard } from "src/components/index";
import CitiesPieChart from "./Stats/CitiesPieChart.vue";
import Notifications from "./Stats/Notifications.vue";
import ServicesBarChart from "./Stats/ServicesBarChart.vue";
export default {
  components: {
    StatsCard,
    ServicesBarChart,
    CitiesPieChart,
    Notifications,
  },
  data() {
    return {
      dashboard: {
        totals: {
          international: 0,
          national: 0,
          total: 0,
          international_last: 0,
          national_last: 0,
          total_last: 0,
        },
        cities: [],
        notifications: [],
        services: [],
      },
      loading: false,
    };
  },
  mounted() {
    this.getDashboard();
  },
  computed: {
    dashboardStatus() {
      return this.$store.state.dashboard.entity.status;
    },
  },
  methods: {
    getDashboard() {
      this.$store.dispatch("dashboard/getDashboard");
    },
    notificacion(title, msg, typ) {
      this.$notify({
        title: title,
        message: msg,
        type: typ,
      });
    },
  },
  watch: {
    dashboardStatus(val) {
      if (val.loading) {
        this.loading = true;
      }

      if (val.error) {
        this.notificacion("Error", val.error.error, "danger");
        this.loading = false;
      }

      if (val.loaded) {
        this.dashboard = this.$store.state.dashboard.entity.data;
        this.loading = false;
      }
    },
  },
};
</script>
<style>
</style>
