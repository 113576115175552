import { envioService } from '@/_services';

export const envio = {
  namespaced: true,
  state: {
    entity: {
      data: [],
      status: {},
    },
    dirHabituales: {
      data: {},
      status: {},
    },
    responseManifiesto: {
      data: {},
      status: {},
    },
    EstadosEnvio: {
      data: {},
      status: {}
    },
    responseModificar: {
      data: {},
      status: {},
    },
    responseJustificante: {
      data: {},
      status: {},
    },
    envio: {
      data: [],
      status: {}
    },
    enviosEtiqueta: {
      data: [],
      status: {}
    },
    logisticaInversa: {
      data: null,
      status: {}
    }
  },
  mutations: {
    logisticaInversaRequest(state) {
      state.logisticaInversa.status = {
        creating: true,
      };
    },
    logisticaInversaError(state, error) {
      state.logisticaInversa.data = null;
      state.logisticaInversa.status = {
        error,
      };
    },
    logisticaInversaSuccess(state, cliente) {
      state.logisticaInversa.data = cliente;
      state.logisticaInversa.status = {
        created: true,
      };
    },
    envioEtiquetaRequest(state) {
      state.enviosEtiqueta.status = {
        loading: true
      }
    },
    envioEtiquetaSuccess(state, pdf) {
      state.enviosEtiqueta.data = pdf
      state.enviosEtiqueta.status = {
        loaded: true
      }
    },
    envioEtiquetaError(state, error) {
      state.enviosEtiqueta.data = null
      state.enviosEtiqueta.status = {
        error
      }
    },
    newEnvioRequest(state) {
      state.entity.status = {
        creating: true,
      };
    },
    newEnvioError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    newEnvioSuccess(state, envio) {
      state.entity.data = envio;
      state.entity.status = {
        created: true,
      };
    },
    getDirHabitualesRequest(state) {
      state.dirHabituales.status = {
        loading: true,
      };
    },
    getDirHabitualesError(state, error) {
      state.dirHabituales.data = null;
      state.dirHabituales.status = {
        error,
      };
    },
    getDirHabitualesSuccess(state, direccion) {
      state.dirHabituales.data = direccion;
      state.dirHabituales.status = {
        loaded: true,
      };
    },
    getManifiestoRequest(state) {
      state.responseManifiesto.status = {
        loading: true,
      };
    },
    getManifiestoSuccess(state, manifiesto) {
      state.responseManifiesto.data = manifiesto;
      state.responseManifiesto.status = {
        loaded: true,
      };
    },
    getManifiestoError(state, error) {
      state.responseManifiesto.data = null;
      state.responseManifiesto.status = {
        error,
      };
    },
    getEstadosEnvioError(state, error) {
      state.EstadosEnvio.data = null;
      state.EstadosEnvio.status = {
        error,
      };
    },
    getEstadosEnvioSuccess(state, estados) {
      state.EstadosEnvio.data = estados;
      state.EstadosEnvio.status = {
        loaded: true,
      };
    },
    getEstadosEnvioRequest(state) {
      state.EstadosEnvio.status = {
        loading: true,
      };
    },
    modificarEstadoError(state, error) {
      state.responseModificar.data = null;
      state.responseModificar.status = {
        error,
      };
    },
    modificarEstadoSuccess(state, estados) {
      state.responseModificar.data = estados;
      state.responseModificar.status = {
        loaded: true,
      };
    },
    modificarEstadoRequest(state) {
      state.responseModificar.status = {
        loading: true,
      };
    },
    getJustificanteRequest(state) {
      state.responseJustificante.status = {
        loading: true,
      };
    },
    getJustificanteSuccess(state, justificante) {
      state.responseJustificante.data = justificante;
      state.responseJustificante.status = {
        loaded: true,
      };
    },
    getJustificanteError(state, error) {
      state.responseJustificante.data = null;
      state.responseJustificante.status = {
        error,
      };
    },
    getEnvioRequest(state) {
      state.envio.status = {
        loading: true,
      };
    },
    getEnvioSuccess(state, env) {
      state.envio.data = env;
      state.envio.status = {
        loaded: true,
      };
    },
    getEnvioError(state, error) {
      state.envio.data = null
      state.envio.status = {
        error
      }
    },
  },
  actions: {
    getEnvio({ commit }, envioId) {
      commit('getEnvioRequest');
      envioService.getEnvio(envioId)
        .then((env) => {
          commit('getEnvioSuccess', env);
        })
        .catch((error) => {
          commit('getEnvioError', error);
        });
    },
    sendEnvio({ commit }, body) {
      commit('newEnvioRequest');
      envioService.sendEnvio(body)
        .then((env) => {
          commit('newEnvioSuccess', env);
        })
        .catch((error) => {
          commit('newEnvioError', error);
        });
    },
    getDirHabituales({ commit }, body) {
      commit('getDirHabitualesRequest');
      envioService.getDirHabituales(body)
        .then((env) => {
          commit('getDirHabitualesSuccess', env);
        })
        .catch((error) => {
          commit('getDirHabitualesError', error);
        });
    },
    getManifiesto({ commit }, request) {
      commit('getManifiestoRequest');
      envioService.getManifiesto(request)
        .then((manifiesto) => {
          commit('getManifiestoSuccess', manifiesto);
        })
        .catch((error) => {
          commit('getManifiestoError', error);
        });
    },
    getEstadosEnvio({ commit }, body) {
      commit('getEstadosEnvioRequest');
      envioService.getEstadosEnvio(body)
        .then((env) => {
          commit('getEstadosEnvioSuccess', env);
        })
        .catch((error) => {
          commit('getEstadosEnvioError', error);
        });
    },
    modificarEstado({ commit }, body) {
      commit('modificarEstadoRequest');
      envioService.modificarEstado(body)
        .then((env) => {
          commit('modificarEstadoSuccess', env);
        })
        .catch((error) => {
          commit('modificarEstadoError', error);
        });
    },
    getJustificante({ commit }, request) {
      commit('getJustificanteRequest');
      envioService.getJustificante(request)
        .then((justificante) => {
          commit('getJustificanteSuccess', justificante);
        })
        .catch((error) => {
          commit('getJustificanteError', error);
        });
    },
    envioEtiqueta({ commit }, body) {
      commit('envioEtiquetaRequest');
      envioService
        .envioEtiqueta(body)
        .then((pdf) => {
          commit('envioEtiquetaSuccess', pdf);
        })
        .catch((error) => {
          commit('envioEtiquetaError', error);
        });
    },
    logisticaInversa({ commit }, body) {
      commit('logisticaInversaRequest');
      envioService.logisticaInversa(body)
        .then((envio) => {
          commit('logisticaInversaSuccess', envio);
        })
        .catch((error) => {
          commit('logisticaInversaError', error);
        });
    },
  },
};