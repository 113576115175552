import { partnerService } from "@/_services";
export const partner = {
  namespaced: true,
  state: {
    solucion: {
      data: {},
      status: {},
    },
    rma: {
      data: {},
      status: {},
    },
  },
  mutations: {
    partnerSolucionRequest(state) {
      state.solucion.status = {
        solving: true,
      };
    },
    partnerSolucionError(state, error) {
      state.solucion.data = null;
      state.solucion.status = {
        error,
      };
    },
    partnerSolucionSuccess(state, res) {
      state.solucion.data = res;
      state.solucion.status = {
        solved: true,
      };
    },
    partnerRMARequest(state) {
      state.rma.status = {
        solving: true,
      };
    },
    partnerRMAError(state, error) {
      state.rma.data = null;
      state.rma.status = {
        error,
      };
    },
    partnerRMASuccess(state, res) {
      state.rma.data = res;
      state.rma.status = {
        solved: true,
      };
    },
  },
  actions: {
    partnerSolucion({ commit }, req) {
      commit("partnerSolucionRequest");
      partnerService
        .partnerSolucion(req.id, req.body)
        .then((res) => {
          commit("partnerSolucionSuccess", res);
        })
        .catch((error) => {
          commit("partnerSolucionError", error);
        });
    },
    partnerRMA({ commit }, req) {
      commit("partnerRMARequest");
      partnerService
        .partnerRMA(req.id, req.tk, req.body)
        .then((res) => {
          commit("partnerRMASuccess", res);
        })
        .catch((error) => {
          commit("partnerRMAError", error);
        });
    },
  },
};
