import { enviosAgrupadosService } from "@/_services";
export const enviosAgrupados = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    etiquetas: {
      data: {},
      status: {},
    },
    finalizar: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getEnviosAgrupadosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getEnviosAgrupadosError(state, error) {
      state.list.data = [];
      state.list.status = {
        loading: false,
        error,
      };
    },
    getEnviosAgrupadosSuccess(state, env) {
      state.list.data = env;
      state.list.status = {
        loaded: true,
      };
    },
    printEtiquetaRequest(state) {
      state.etiquetas.status = {
        loading: true,
      };
    },
    printEtiquetaSuccess(state, pdf) {
      state.etiquetas.data = pdf;
      state.etiquetas.status = {
        loaded: true,
      };
    },
    printEtiquetaError(state, error) {
      state.etiquetas.data = null;
      state.etiquetas.status = {
        error,
      };
    },
    finalizarRequest(state) {
      state.finalizar.status = {
        loading: true,
      };
    },
    finalizarSuccess(state, pdf) {
      state.finalizar.data = pdf;
      state.finalizar.status = {
        loaded: true,
      };
    },
    finalizarError(state, error) {
      state.finalizar.data = null;
      state.finalizar.status = {
        error,
      };
    },
  },
  actions: {
    getEnviosAgrupados({ commit }, req) {
      commit("getEnviosAgrupadosRequest");
      enviosAgrupadosService
        .getEnviosAgrupados(req.pagination, req.filters)
        .then((env) => {
          commit("getEnviosAgrupadosSuccess", env);
        })
        .catch((error) => {
          commit("getEnviosAgrupadosError", error);
        });
    },
    printEtiqueta({ commit }, id) {
      commit("printEtiquetaRequest");
      enviosAgrupadosService
        .imprimirEtiquetas(id)
        .then((pdf) => {
          commit("printEtiquetaSuccess", pdf);
        })
        .catch((error) => {
          commit("printEtiquetaError", error);
        });
    },
    finalizar({ commit }, body) {
      commit("finalizarRequest");
      enviosAgrupadosService
        .finalizar(body)
        .then((pdf) => {
          commit("finalizarSuccess", pdf);
        })
        .catch((error) => {
          commit("finalizarError", error);
        });
    },
  },
};
