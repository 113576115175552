import { createAxios } from "./axiosHttpMarconi";

function getEnviosAgrupados(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `cliente/${user.extraData.clienteId}/envios-agrupados`;

  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }

  if (filters && filters.servicios) {
    urlPath += `&servicios=${filters.servicios.join()}`;
  }

  if (filters && filters.estados) {
    urlPath += `&estados=${filters.estados.join()}`;
  }

  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.prop}&order=${filters.sort.order}`;
  }

  if (filters && filters.referencia) {
    urlPath += `&referencia=${filters.referencia}`;
  }

  return axios.get(urlPath);
}
function imprimirEtiquetas(id) {
  const axios = createAxios();
  const urlPath = `envios-agrupados/${id}/etiquetas`;
  return axios.get(urlPath, { responseType: "blob" });
}
function finalizar(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  const urlPath = `cliente/${user.extraData.clienteId}/envios-agrupados/finalizar`;
  return axios.post(urlPath, body);
}

export const enviosAgrupadosService = {
  getEnviosAgrupados,
  imprimirEtiquetas,
  finalizar,
};
