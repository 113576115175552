import createAxios from './axiosHttpMarconi';
export const franquiciaService = {
  getFranquiciasById
};

function getFranquiciasById() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  const urlPath = `franquicia/${user.extraData.franquicia}`;
  return axios.get(urlPath);
}