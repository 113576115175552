<template>
  <label class="estado" :class="[
      { [`estado-${label.toLowerCase()}`]: type },
    ]">
    {{ label.replace('_',' ').toUpperCase() }}
  </label>
</template>
<script>
  export default {
    name: 'estado',
    props: {
      label: [String],
      type: {
        type: String,
        default: 'primary',
        description: 'Tipo Estado(primary|secondary|danger etc)'
      }
    },
    data () {
      return {
      }
    },
    mounted(){
    },
    computed: {
    },
    created () {
    }
  }
</script>
<style scoped>
  .card label{
    color: white;
  }
</style>
