import createAxiosV2 from './axiosHttpMarconi';
import createAxiosLogin from './axiosLogin';

export const userService = {
  login,
  logout,
  oneShotToken,
  changePassword,
  tieneRol,
  esFranquicia,
  esCliente,
  usersCliente,
  nuevoUsuario,
  getRoles,
  resetPassword,
  getEnlaceRecuperacion
};

function login(user, password) {
  const axios = createAxiosLogin();
  return axios.post('/auth/login', { user, password }).then((userLogged) => {
    if (userLogged) {
      localStorage.setItem('user', JSON.stringify(userLogged));
    }
    return user;
  });
}


function getEnlaceRecuperacion(body) {
  const axios = createAxiosLogin();
  const urlPath = '/auth/password/link';
  return axios.post(urlPath, body);
}

function resetPassword(body, token) {
  //console.log(token);
  const axios = createAxiosLogin();
  const urlPath = `/auth/password/reestablecer?ost=${token}`
  return axios.post(urlPath, body)
}

function getRoles(tipo) {
  const axios = createAxiosV2();
  let urlPath = `/auth/roles`;
  if(tipo){
    urlPath += `?tipo=${tipo}`
  }
  return axios.get(urlPath);
}

function nuevoUsuario(id, user) {
  const axios = createAxiosV2();
  let urlPath = `/cliente/${id}/auth/user`;
  return axios.post(urlPath, user);
}

function usersCliente(id, filters,pagination) {
  const axios = createAxiosV2();
  let urlPath = `/cliente/${id}/auth/user`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += '?page=0&limit=10';
  }
  if (filters && filters.user) {
    urlPath += `&user=${filters.user}`;
  }
  if (filters && filters.roles) {
    urlPath += `&roles=${filters.roles}`;
  }
  if (filters && filters.status) {
    urlPath += `&status=${filters.status}`;
  }
  return axios.get(urlPath);
}

function logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('cliente');
}

function oneShotToken() {
  const axios = createAxiosV2();
  return axios.get('/auth/token');
}

function tieneRol(user, rol) {
  return user.roles != null && user.roles.indexOf(rol) > -1;
}

function esFranquicia(user) {
  return tieneRol(user, 'FRANQUICIA');
}

function esCliente(user) {
  return tieneRol(user, 'CLIENTE');
}

function changePassword(body) {
  const axios = createAxiosV2();
  const urlPath = '/auth/password';
  return axios.post(urlPath, body);
}