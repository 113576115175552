<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header" class="header-login">
                  <div>
                    <h3 class="card-title text-center">
                      Iniciar Sesi&oacute;n
                    </h3>
                  </div>
                  <div>
                    <img src="/static/img/logo-oxperta-login.png" />
                  </div>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      :error="failed ? 'El usuario es requerido' : null"
                      :hasSuccess="passed"
                      label="Usuario"
                      name="email"
                      v-model="email"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="password"
                      :error="failed ? 'Contraseña requerida' : null"
                      :hasSuccess="passed"
                      name="password"
                      label="Contraseña"
                      v-model="password"
                    >
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-fill btn-info btn-round btn-wd"
                    :disabled="!isActive"
                  >
                    Iniciar Sesión
                  </button>
                  <br />
                  <div class="forgot">
                    <router-link to="/recover_password" class="card-category">
                      ¿Olvidaste tu contraseña?
                    </router-link>
                  </div>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {
      email: "",
      password: "",
      isActive: true,
    };
  },
  mounted() {},
  computed: {
    statusLogin() {
      return this.$store.state.user.entity.status;
    },
    user() {
      return this.$store.state.user.entity.data;
    },
  },
  methods: {
    submit() {
      const model = {
        user: this.email,
        password: this.password,
      };
      this.isActive = false;
      this.$store.dispatch("user/login", model);
      //this.$router.push( {name: 'dashboard'} );
    },
    notificacion(titulo, mensaje, tipo) {
      this.$message({
        title: titulo,
        message: mensaje,
        type: tipo,
      });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  watch: {
    statusLogin() {
      if (this.statusLogin.logged) {
        this.isActive = true;
        this.errorUser = false;
        let user = JSON.parse(localStorage.getItem("user"));
        if (user.roles.includes("CLIENTE")) {
          this.$router.push("/");
        } else {
          this.notificacion(
            "Error",
            "No cuenta con los permisos requeridos!",
            "error"
          );
        }
      } else if (this.statusLogin.error) {
        this.isActive = true;
        this.notificacion("Error", "Error en el usuario o contraseña", "error");
      }
    },
  },
  beforeUnmount() {
    this.closeMenu();
  },
};
</script>
<style scoped lang="scss">
.login-button {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.header-login {
  display: grid;
  > div {
    width: 100%;
  }

  justify-content: center;
}
</style>
