import {
    masivoService
  } from '@/_services';
  
  export const masivo = {
    namespaced: true,
    state: {
      list: {
        data: [],
        status: {},
      },
      responseModificar: {
        data: {},
        status: {},
      },
      newIncidencia:{
        data: {},
        status: {},
      },
      masivoId: {
        data: [],
        status: {},
      },
      download: {
        data: {},
        status: {}
      },
      enviosMasivos: {
        data: {},
        status: {}
      }
    },
    mutations: {
      getEnviosMasivosRequest(state) {
        state.enviosMasivos.status = {
          loading: true,
        };
      },
      getEnviosMasivosError(state, error) {
        state.enviosMasivos.data = [];
        state.enviosMasivos.status = {
          error,
        };
      },
      getEnviosMasivosSuccess(state, cp) {
        state.enviosMasivos.data = cp;
        state.enviosMasivos.status = {
          loaded: true,
        };
      },
      getMasivoRequest(state) {
        state.list.status = {
          loading: true,
        };
      },
      cancelMasivoRequest(state, error) {
        state.list.data = [];
        state.list.status = {
          error,
        };
      },
      getMasivoSuccess(state, cp) {
        state.list.data = cp;
        state.list.status = {
          loaded: true,
        };
      },

      modificarEstadoError(state, error) {
        state.responseModificar.data = null;
        state.responseModificar.status = {
          error,
        };
      },
      modificarEstadoSuccess(state, estados) {
        state.responseModificar.data = estados;
        state.responseModificar.status = {
          loaded: true,
        };
      },
      modificarEstadoRequest(state) {
        state.responseModificar.status = {
          loading: true,
        };
      },

      newIncidenciaMasivoRequest(state) {
        state.newIncidencia.status = {
          creating: true,
        };
      },
      newIncidenciaMasivoError(state, error) {
        state.newIncidencia.data = null;
        state.newIncidencia.status = {
          error,
        };
      },
      newIncidenciaMasivoSuccess(state, envio) {
        state.newIncidencia.data = envio;
        state.newIncidencia.status = {
          created: true,
        };
      },
      getMasivoIdRequest(state) {
        state.masivoId.status = {
          loading: true,
        };
      },
      getMasivoIdError(state, error) {
        state.masivoId.data = [];
        state.masivoId.status = {
          error,
        };
      },
      getMasivoIdSuccess(state, cp) {
        state.masivoId.data = cp;
        state.masivoId.status = {
          loaded: true,
        };
      },
      getMasivoDownloadRequest(state) {
        state.download.status = {
          downloading: true,
        };
      },
      getMasivoDownloadError(state, error) {
        state.download.data = {};
        state.download.status = { 
          error,
        };
      },
      getMasivoDownloadSuccess(state, response) {
        state.download.data = response;
        state.download.status = {
          downloaded: true,
        };
      },
    },
    actions: {
      getMasivos({ commit }, req) {
        commit('getMasivoRequest');
        masivoService
          .getMasivos(req.pagination, req.filters)
          .then((mas) => {
            commit('getMasivoSuccess', mas);
          })
          .catch((error) => {
            commit('cancelMasivoRequest', error);
        });
      },
      getMasivo({ commit }, req) {
        commit("getMasivoIdRequest");
        masivoService
          .getMasivo(req)
          .then((masivo) => {
            commit("getMasivoIdSuccess", masivo);
          })
          .catch((error) => {
            commit("getMasivoIdError", error);
          });
      },
      modificarEstado({ commit }, body) {
        commit('modificarEstadoRequest');
        masivoService.modificarEstado(body)
          .then((env) => {
            commit('modificarEstadoSuccess', env);
          })
          .catch((error) => {
            commit('modificarEstadoError', error);
          });
      },
      nuevaIncidenciaMasivo({ commit }, req) {
        commit('newIncidenciaMasivoRequest');
        masivoService.nuevaIncidenciaMasivo(req.data, req.mensaje)
          .then((incidencia) => {
            commit('newIncidenciaMasivoSuccess', incidencia);
          })
          .catch((error) => {
            commit('newIncidenciaMasivoError', error);
          });
      },
      getMasivoDownload({ commit }, idMasivo) {
        commit('getMasivoDownloadRequest');
        masivoService
          .getMasivoDownload(idMasivo)
          .then((response) => {
            commit('getMasivoDownloadSuccess', response);
          })
          .catch((error) => {
            commit('getMasivoDownloadError', error);
          });
      },
      getEnviosMasivos({ commit }, idMasivo) {
        commit('getEnviosMasivosRequest');
        masivoService
          .getEnviosMasivos(idMasivo)
          .then((response) => {
            commit('getEnviosMasivosSuccess', response);
          })
          .catch((error) => {
            commit('getEnviosMasivosError', error);
          });
      },
    },
  };