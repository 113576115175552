import { createAxiosV2 } from './axiosHttpMarconi';


function getMasivos(pagination, filters) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `cliente/${user.extraData.clienteId}/masivo`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += '?page=0&limit=10';
  } if (filters && filters.order) {
    urlPath += `&sort=createdAt&order=${filters.order}`
  } else {
    urlPath += `&sort=createdAt&order=descending`
  } if (filters && filters.estados) {
    urlPath += `&estados=${filters.estados}`
  }
  return axios.get(urlPath);
}

function getEnviosMasivos(idMasivo) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/cliente/${user.extraData.clienteId}/masivo/${idMasivo}`;
  return axios.get(urlPath);
}

function modificarEstado(masivoId) {
  const axios = createAxiosV2();
  let urlPath = `masivo/${masivoId}/anular`;
  let body = {'codigo' : '19'};
  return axios.post(urlPath, body);
}

function nuevaIncidenciaMasivo(data, mensaje) {
  const axios = createAxiosV2();
  let urlPath = `cliente/${data.cliente}/masivo/${data.masivo}/incidencias`;
  return axios.post(urlPath, { mensaje } );
}

function getMasivo(masivoId) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `cliente/${user.extraData.clienteId}/masivo/${masivoId}`;
  return axios.get(urlPath);
}

function getMasivoDownload(idMasivo){
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/cliente/${user.extraData.clienteId}/masivo/${idMasivo}/download`;
  return axios.get(urlPath, {
    responseType: 'blob'
  });
}

export const masivoService = {
  getMasivos,
  modificarEstado,
  nuevaIncidenciaMasivo,
  getMasivo,
  getMasivoDownload,
  getEnviosMasivos
};
