<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header" class="header-login">
                  <div>
                    <h3 class="card-title text-center">Restaurar Contraseña</h3>
                  </div>
                  <div>
                    <img src="/static/img/logo-oxperta-login.png" />
                  </div>
                </div>
                <div>
                  <ValidationProvider
                    name="password"
                    :rules="{
                      required: true,
                      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
                    }"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="password"
                      :error="
                        failed
                          ? 'Mínimo 8 carácteres, con alguna minúscula, mayúscula y número, no se permiten puntos ni símbolos'
                          : null
                      "
                      :hasSuccess="passed"
                      name="password"
                      label="Nueva Contraseña"
                      v-model="newPassword"
                      class="pt-2"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    :rules="{
                      is: newPassword,
                      required: true,
                      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
                    }"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="password"
                      :error="failed ? textError() : null"
                      :hasSuccess="passed"
                      name="password"
                      label="Repetir Contraseña"
                      v-model="repeatPassword"
                      class="pt-2"
                    >
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center pt-4">
                  <button
                    type="submit"
                    class="btn btn-fill btn-info btn-round btn-wd"
                    :disabled="!isActive"
                  >
                    Restaurar
                  </button>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import { FadeRenderTransition } from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, regex, min, is, email } from "vee-validate/dist/rules";

extend("regex", regex);
extend("is", is);
extend("required", required);
extend("min", min);

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {
      email: "",
      newPassword: "",
      repeatPassword: "",
      isActive: true,
    };
  },
  mounted() {
    this.email = this.$route.query.u;
  },
  computed: {
    statusReset() {
      return this.$store.state.user.reset.status;
    },
  },
  methods: {
    submit() {
      let body = {
        newPassword: this.newPassword,
        confirmPassword: this.repeatPassword,
        user: this.email,
      };
      this.$store.dispatch("user/resetLogin", {
        body: body,
        token: this.$route.query.ost,
      });
    },
    notificacion(titl, msg, typ) {
      this.$message({
        title: titl,
        message: msg,
        type: typ,
      });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    textError() {
      if (this.newPassword != this.repeatPassword) {
        return "Contraseña no coincide";
      } else
        return "Mínimo 8 carácteres, con alguna minúscula, mayúscula y número, no se permiten puntos ni símbolos";
    },
    toLogin() {
      this.$router.push({ path: "/login" });
    },
  },
  watch: {
    statusReset(val) {
      if (val.reseted) {
        this.isActive = true;
        this.notificacion("Restablecer!", "Contraseña restaurada", "success");
        this.toLogin();
      } else if (val.error) {
        this.isActive = true;
        this.notificacion("Error!", val.error, "error");
      }
    },
  },
  beforeUnmount() {
    this.closeMenu();
  },
};
</script>
<style scoped lang="scss">
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.header-login {
  display: grid;

  > div {
    width: 100%;
  }

  justify-content: center;
}
</style>
