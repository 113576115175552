import { helpService } from "@/_services";

export const help = {
  namespaced: true,
  state: {
    file: {
      data: {},
      status: {},
    },
    message: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getFileRequest(state) {
      state.file.status = {
        downloading: true,
      };
    },
    getFileError(state, error) {
      state.file.data = null;
      state.file.status = {
        error,
      };
    },
    getFileSuccess(state, blob) {
      state.file.data = blob;
      state.file.status = {
        downloaded: true,
      };
    },
    sendMessageRequest(state) {
      state.message.status = {
        sending: true,
      };
    },
    sendMessageError(state, error) {
      state.message.data = null;
      state.message.status = {
        error,
      };
    },
    sendMessageSuccess(state, blob) {
      state.message.data = blob;
      state.message.status = {
        sended: true,
      };
    },
  },
  actions: {
    getFile({ commit }, file) {
      commit("getFileRequest");
      helpService
        .getFile(file)
        .then((blob) => {
          commit("getFileSuccess", blob);
        })
        .catch((error) => {
          commit("getFileError", error);
        });
    },
    sendMessage({ commit }, file) {
      commit("sendMessageRequest");
      helpService
        .sendMessage(file)
        .then((blob) => {
          commit("sendMessageSuccess", blob);
        })
        .catch((error) => {
          commit("sendMessageError", error);
        });
    },
  },
};
