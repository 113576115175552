import { createAxios, createAxiosV2 } from './axiosHttpMarconi';
export const clienteService = {
  getCliente,
  loadCliente,
  getLocalCliente,
  reloadCliente,
};

function getCliente() {
  return new Promise((resolve, reject) => {
    const axios = createAxiosV2();
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      let urlPath = `cliente/${user.extraData.clienteId}`;
      return axios.get(urlPath)
        .then((response) => { resolve(response); })
        .catch((reason) => { reject(reason) });
    } else {
      reject(new Error("Usuario no logado"))
    }
  });
}

function loadCliente() {
  return new Promise((resolve, reject) => {
    getCliente().then((cliente) => {
      localStorage.setItem('cliente', JSON.stringify(cliente));
      resolve(cliente);
    }).catch((error) => {
      reject(error);
    });
  });
}

function getLocalCliente() {
  return JSON.parse(localStorage.getItem('cliente'));
}

function reloadCliente() {
  setInterval(() => { loadCliente(); }, 3600000);
}