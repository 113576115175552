<template>
  <div class="dropdown">
    <div
        :id="idToggle"
        class="dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside">
      <el-input
        :size="size"
        :placeholder="placeholder" 
        :id="idSearchAutocomplete"
        v-model="text"
        class="searchInput"
        @input="textChanged"
      >
      <i :id="idIcon" slot="suffix" class="el-input__icon" :class="iconText" @click="clickIcono()"></i>
      </el-input>
    </div>
    <div
      class="dropdown-menu mt-1"
      :id="idDropdownMenu"
    >
      <div>
        <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
          <slot>
            <p class="infinite-list-item empty text-center">Sin datos</p>
          </slot>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    value:{
      require: true,
      default:()=>{
        return{}
      }
    },
    placeholder:{
      type:String,
      default:()=>""
    },
    size:{
      type:String,
      default:()=>""
    },
    idInput:{
      type:String,
      default:()=>""
    },
  },
  data() {
    return {
      text:"",
      resizeObserver: null,
      localValue:this.value,
      page:0,
      dropdownMenu:{},
      ancho:0,
      iconText:"el-icon-search",
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.onResize);
    let input=document.getElementById(this.idSearchAutocomplete);
    this.resizeObserver.observe(input);
    this.dropdownMenu=document.getElementById(this.idDropdownMenu);
    let icono=document.getElementById(this.idIcon);
    /* icono.addEventListener("mouseenter", (event) => {
      if(Object.entries(this.localValue).length !== 0)
        this.iconText="el-icon-circle-close";
    },false);
    icono.addEventListener('mouseleave', (e) => {
      this.iconText="el-icon-search";
    }); */
  },
  computed: {
    idToggle(){
      return "idToggle"+uuidv4();
    },
    idDropdownMenu(){
      return "dropdown-busqueda"+uuidv4();
    },
    idSearchAutocomplete(){
      return "searchAutocomplete"+uuidv4();
    },
    idIcon(){
      return "icono"+uuidv4();
    }
  },
  methods: {
    onResize() {
      this.ancho=document.getElementById(this.idSearchAutocomplete)?document.getElementById(this.idSearchAutocomplete).offsetWidth:null;
      if(this.ancho)
        this.dropdownMenu.style.width=this.ancho+"px";
    },
    load () {
      this.page+=1,
      this.$emit("load",this.page,this.text,"scroll");
    },
    textChanged(val){
      if(!this.dropdownMenu.classList.contains("show")){
        document.getElementById(this.idToggle).click();
      }
      this.page=0;
      this.$emit("load",this.page,val,"textChanged");
    },
    selected(val,label){
      this.localValue=val;
      this.text=label;
    },
    clickToggle(){
      document.getElementById(this.idToggle).click();
    },
    clickIcono(){
      if(this.iconText=="el-icon-circle-close"){
        this.text="";
        this.localValue={};
        this.clickToggle();
      }
    }
  },
  watch: {
    value(newVal){
      this.localValue=newVal;
      if(Object.entries(this.localValue).length === 0){
        this.text="";
      }
    },
    localValue(newVal){
      this.$emit("input",newVal);
      this.$emit("change",newVal);
    },
  },
};
</script>

<style scoped>
.dropdown-toggle::after{
  content: none;
}
.infinite-list{
  max-height: 150px;
  padding-left: 0;
  margin-bottom: 0px;
}
li{
  list-style:none;
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.empty{
  color: #606266;
  font-size: 14px;
  margin-bottom: 0px;
}
.el-icon-circle-close{
  cursor: pointer;
}
</style>
<style>
.searchInput .el-input__inner {
  border-color: #409EFF !important;
}
</style>