import { createAxios } from "./axiosHttpMarconi";

export const partnerService = { partnerSolucion, partnerRMA };

function partnerSolucion(idIncidencia, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/partner/incidencia/${idIncidencia}`;
  return axios.post(urlPath, body);
}

function partnerRMA(id, tk, req) {
  const axios = createAxios();
  let urlPath = `partner/rma/${id}?tk=${tk}`;
  return axios.post(urlPath, req);
}
