import { createAxios, createAxiosV2 } from './axiosHttpMarconi';
export const facturaService = {
  getFacturas,
  downloadInvoice
};

function getFacturas(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/cliente/${user.extraData.clienteId}/facturas`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += '?page=0&limit=10';
  } 
  if (filters && filters.sort) {
    urlPath += `&sort=createdAt&order=${filters.sort.order}`
  } else {
    urlPath += `&sort=createdAt&order=descending`
  } 
  if (filters && filters.numeroFactura) {
    urlPath += `&numero=${filters.numeroFactura}`
  }
  if (filters && filters.fecha[0]) {
    urlPath += `&fecha_inicio=${filters.fecha[0]}`
  }
  if (filters && filters.fecha[1]) {
    urlPath += `&fecha_fin=${filters.fecha[1]}`
  }
  
  return axios.get(urlPath);
}

function downloadInvoice(request) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/cliente/${user.extraData.clienteId}/factura/${request.facturaId}/donwload`;
  if (request && request.tipoDocumento) {
    urlPath += `?tipo_documento=${request.tipoDocumento}`;
  }
  return axios.get(urlPath, {
    responseType: 'blob'
  });
}