import { createAxiosTk, createAxios } from "./axiosHttpMarconi";

export const puntoRedService = {
  getPuntosRedByPostalCode,
  busquedaOficinas,
  getPuntosAsExcel,
};

function getPuntosAsExcel(request) {
  const axios = createAxios();
  let urlPath = `/puntosRedEntrega/exportar?page=0`;
  if (request && request.autocomplete) {
    urlPath += `&autocomplete=${request.autocomplete}`;
  }
  if (request && request.esInternacional != null) {
    urlPath += `&esInternacional=${request.esInternacional}`;
  }
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getPuntosRedByPostalCode(filters) {
  const axios = createAxiosTk();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath =
    filters && filters.token
      ? `/puntosRed?`
      : `/cliente/${user.extraData.clienteId}/puntosRed?`;
  if (filters && filters.codigoPostal) {
    urlPath += `codigoPostal=${filters.codigoPostal}`;
  }
  if (filters && filters.courier) {
    urlPath += `&courier=${filters.courier}`;
  }
  if (filters && filters.admiteEntrega) {
    urlPath += `&admiteEntrega=${filters.admiteEntrega}`;
  }
  if (filters && filters.admiteLogisticaInversa) {
    urlPath += `&admiteLogisticaInversa=${filters.admiteLogisticaInversa}`;
  }
  if (filters && filters.token) {
    urlPath += `&${filters.type}=${filters.token}`;
  }
  return axios.get(urlPath);
}
function busquedaOficinas(pagination, request) {
  const axios = createAxios();
  let urlPath = `/puntosRedEntrega`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (request && request.autocomplete) {
    urlPath += `&autocomplete=${request.autocomplete}`;
  }
  if (request && request.courier) {
    urlPath += `&courier=${request.courier}`;
  }
  if (request && request.esInternacional != null) {
    urlPath += `&esInternacional=${request.esInternacional}`;
  }
  return axios.get(urlPath, request);
}
