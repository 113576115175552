import { createAxiosV2 } from "./axiosHttpMarconi";
export const incidenciaService = {
  getIncidencias,
  getIncidenciaById,
  getIncidenciaChats,
  patchIncidencia,
  postMensaje,
  deleteChatIncidencia,
  putChatIncidencia,
  getAdjunto,
};
function getIncidencias(pagination, filters) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/incidencias`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.fecha) {
    urlPath += `&fecha_inicio=${filters.fecha[0]}`;
    urlPath += `&fecha_fin=${filters.fecha[1]}`;
  }
  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }
  if (filters && filters.localizador) {
    urlPath += `&localizador=${filters.localizador}`;
  }
  if (filters && filters.referencia) {
    urlPath += `&referencia=${filters.referencia}`;
  }
  if (filters && filters.numero) {
    urlPath += `&numero=${filters.numero}`;
  }
  if (
    filters &&
    filters.estadoContestacion &&
    filters.estadoContestacion.length > 0
  ) {
    urlPath += `&estadoContestacion=${filters.estadoContestacion}`;
  }
  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  /* if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.sort}&order=${filters.sort.order}`;
  } else {
    urlPath += `&sort=sla.fechaResolucion&order=ascending`;
  } */
  if (filters && filters.estadoLeido && filters.estadoLeido.length > 0) {
    urlPath += `&estadoLeido=${filters.estadoLeido}`;
  }
  return axios.get(urlPath);
}

function getIncidenciaById(idIncidencia) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/incidencia/${idIncidencia}`;
  return axios.get(urlPath);
}

function getIncidenciaChats(idIncidencia, pagination, filters) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/incidencia/${idIncidencia}/chat`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0";
  }
  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.sort}&order=${filters.sort.order}`;
  }
  return axios.get(urlPath);
}

function patchIncidencia(idIncidencia, body) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/incidencia/${idIncidencia}`;
  return axios.put(urlPath, body);
}

function postMensaje(idIncidencia, body) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/incidencia/${idIncidencia}/chat`;
  return axios.post(urlPath, body);
}

function deleteChatIncidencia(idIncidencia, idChat) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/incidencia/${idIncidencia}/chat/${idChat}`;
  return axios.delete(urlPath);
}

function putChatIncidencia(idIncidencia, idChat, body) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/incidencia/${idIncidencia}/chat/${idChat}`;
  return axios.put(urlPath, body);
}

function getAdjunto(idIncidencia, idChat) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/incidencia/${idIncidencia}/chat/${idChat}/adjunto`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}
