<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handlePageChange"
    :current-page="pagination.currentPage"
    :pager-count="5"
    :v-model="pagination.currentPage"
    :page-sizes="pagination.perPageOptions"
    :page-size="pagination.perPage"
    layout="total, sizes, prev, pager, next, jumper"
    :total="pagination.totalItems"
  >
  </el-pagination>
</template>
<script>
export default {
  name: "Pagination",
  props: {
    value: {
      required: true,
      default: () => {
        return {
          actualPagina: 0,
          perPage: 20,
          currentPage: 1,
          perPageOptions: [20, 30, 40, 50],
          totalPages: 0,
          totalItems: 0,
        };
      },
    },
  },
  data() {
    return {
      pagination: this.value,
    };
  },
  computed: {},
  methods: {
    handleSizeChange(val) {
      this.pagination.perPage = val;
      this.$emit("paginationChange", this.pagination);
    },
    handlePageChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.$emit("paginationChange", this.pagination);
    },
  },
  watch: {},
};
</script>
