<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul class="footer-menu">
          <li>
            <router-link :to="{path:'/'}">Dashboard</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        {{ version }}
        <!-- &copy; Coded with
        <i class="fa fa-heart heart"></i> by <a href="https://www.hotaka.io" target="_blank" rel="noopener">Hotaka iKhodi</a>. -->
      </div>
    </div>
  </footer>
</template>
<script>

import { appVersion } from '@/globalVars';
export default {
  components: {
  },
  data() {
    return {
      version: appVersion,
    };
  },
}

</script>
<style>

</style>
