import { rolesService } from "@/_services";

export const roles = {
  namespaced: true,
  state: {
    menus: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getMenusRequest(state) {
      state.menus.status = {
        loading: true,
      };
    },
    getMenusError(state, error) {
      state.menus.data = {};
      state.menus.status = {
        error,
      };
    },
    getMenusSuccess(state, data) {
      state.menus.data = data;
      state.menus.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getMenus({ commit }) {
      commit("getMenusRequest");
      rolesService
        .getMenus()
        .then((res) => {
          commit("getMenusSuccess", res);
        })
        .catch((error) => {
          commit("getMenusError", error);
        });
    },
  },
};
