import { serviciosService } from '@/_services';

export const servicios = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    serviciosCliente: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getServicioByIdRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelServicioByIdRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getServicioByIdSuccess(state, serv) {
      state.list.data = serv;
      state.list.status = {
        loaded: true,
      };
    },
    getServicioByClienteRequest(state) {
      state.serviciosCliente.status = {
        loading: true,
      };
    },
    cancelServicioByClienteRequest(state, error) {
      state.serviciosCliente.data = [];
      state.serviciosCliente.status = {
        error,
      };
    },
    getServicioByClienteSuccess(state, serv) {
      state.serviciosCliente.data = serv;
      state.serviciosCliente.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getServicios({ commit }, tipoServicio) {
      commit('getServicioByIdRequest');
      serviciosService
        .getServicios(tipoServicio)
        .then((serv) => {
          commit('getServicioByIdSuccess', serv);
        })
        .catch((error) => {
          commit('cancelServicioByIdRequest', error);
        });
    },
    getServiciosByCliente({ commit },request) {
      commit('getServicioByClienteRequest');
      serviciosService
        .getServiciosByCliente(request)
        .then((serv) => {
          commit('getServicioByClienteSuccess', serv);
        })
        .catch((error) => {
          commit('cancelServicioByClienteRequest', error);
        });
    },
  },
};

