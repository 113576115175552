import { facturaService } from "@/_services";

export const factura = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    download: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getfacturaRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getfacturaSuccess(state, facturas) {
      state.list.data = facturas;
      state.list.status = {
        loaded: true,
      };
    },
    getfacturaError(state, error) {
      state.list.data = [];
      state.list.status = {
        loaded: true,
        error,
      };
    },
    downloadFacturaRequest(state) {
      state.download.status = {
        loading: true,
      };
    },
    downloadFacturaError(state, error) {
      state.download.data = null;
      state.download.status = {
        error,
      };
    },
    downloadFacturaSuccess(state, factura) {
      state.download.data = factura;
      state.download.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getFacturas({ commit }, req) {
      commit("getfacturaRequest");
      facturaService
        .getFacturas(req.pagination, req.filters)
        .then((facturas) => {
          commit("getfacturaSuccess", facturas);
        })
        .catch((error) => {
          commit("getfacturaError", error);
        });
    },
    downloadFactura({ commit }, req){
      commit("downloadFacturaRequest");
      facturaService
        .downloadInvoice(req)
        .then((factura) => {
          commit("downloadFacturaSuccess", factura);
        })
        .catch((error) => {
          commit("downloadFacturaError", error);
        });
    },
  },
};
