import createAxios from "./axiosHttpMarconi";
export const helpService = {
  getFile,
  sendMessage,
};

function getFile(file) {
  const user = JSON.parse(localStorage.getItem("user"));
  const axios = createAxios();
  let urlPath = `cliente/${user.extraData.clienteId}/help/${file}`;
  return axios.get(urlPath, { responseType: "blob" }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}

function sendMessage(body) {
  const user = JSON.parse(localStorage.getItem("user"));
  const axios = createAxios();
  let urlPath = `cliente/${user.extraData.clienteId}/help`;
  return axios.post(urlPath, body);
}
