import createAxios from './axiosHttpMarconi';


function newContactoDireccion(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `cliente/${user.extraData.clienteId}/contacto`;
  return axios.post(urlPath, body);
}
function updateContactoDireccion(contactoId,body) {
  const user = JSON.parse(localStorage.getItem('user'));
  const axios = createAxios();
  let urlPath = `cliente/${user.extraData.clienteId}/contacto/${contactoId}`;
  return axios.put(urlPath, body);
}

function getContactoDireccion(contactoId) {
  const user = JSON.parse(localStorage.getItem('user'));
  const axios = createAxios();
  let urlPath = `cliente/${user.extraData.clienteId}/contacto/${contactoId}`;
  return axios.get(urlPath);
}
function eliminarContactoDireccion(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `cliente/${user.extraData.clienteId}/contacto/${body.direccionId}`;
  return axios.delete(urlPath);
} 

function getAllContactos(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `cliente/${user.extraData.clienteId}/contacto`;
  if(body.pagination != null) {
    urlPath += `?page=${body.pagination.currentPage - 1}&limit=${body.pagination.perPage}`;
  } else {
    urlPath += '?';
  }
  if (body.tipo) {
    urlPath += `&tipo=${body.tipo}`;
  }
  if (body.defecto) {
    urlPath += `&defecto=${body.defecto}`;
  }
  if (body.contacto) {
    urlPath += `&contacto=${body.contacto}`;
  }
  if (body.direccion) {
    urlPath += `&direccion=${body.direccion}`;
  }
  if (body.normalizada != null) {
    urlPath += `&normalizada=${body.normalizada}`;
  }
  return axios.get(urlPath);
}

export const libretaContactoService = {
  newContactoDireccion,updateContactoDireccion,getContactoDireccion,eliminarContactoDireccion,getAllContactos
};


