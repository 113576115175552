import { dashboardService } from "@/_services";

export const dashboard = {
  namespaced: true,
  state: {
    entity: {
      data: {},
      status: {},
    },
    file: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getDashboardRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getDashboardError(state, error) {
      state.entity.data = [];
      state.entity.status = {
        error,
      };
    },
    getDashboardSuccess(state, serv) {
      state.entity.data = serv;
      state.entity.status = {
        loaded: true,
      };
    },
    getNotificationFileRequest(state) {
      state.file.status = {
        downloading: true,
      };
    },
    getNotificationFileError(state, error) {
      state.file.data = [];
      state.file.status = {
        error,
      };
    },
    getNotificationFileSuccess(state, data) {
      state.file.data = data;
      state.file.status = {
        downloaded: true,
      };
    },
  },
  actions: {
    getDashboard({ commit }) {
      commit("getDashboardRequest");
      dashboardService
        .getDashboard()
        .then((res) => {
          commit("getDashboardSuccess", res);
        })
        .catch((error) => {
          commit("getDashboardError", error);
        });
    },
    getNotificationFile({ commit }, id) {
      commit("getNotificationFileRequest");
      dashboardService
        .getNotificationFile(id)
        .then((res) => {
          commit("getNotificationFileSuccess", res);
        })
        .catch((error) => {
          commit("getNotificationFileError", error);
        });
    },
  },
};
