import createAxios from './axiosHttpMarconi';
export const paisService = {
  getPaises
};

function getPaises(filters) {
  const axios = createAxios();
  let urlPath = 'pais?&sort=_id&order=ascending&';
  if (filters && filters.tipo) {
    urlPath += `tipo=${filters.tipo}&`;
  }
  if (filters.page != null) {
    urlPath += `page=${filters.page}&limit=${filters.limit}&`;
  }  
  return axios.get(urlPath);
}