<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <div class="row">
        <div class="navbar-minimize col">
          <button
            class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block"
            @click="abrirSideBar"
          >
            <i
              :class="
                $sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'
              "
            ></i>
          </button>
        </div>
        <a class="navbar-brand col mt-3">{{ this.$route.name }}</a>
      </div>
      <button
        type="button"
        class="navbar-toggler navbar-toggler-right"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-expanded="false"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="btn-group dropstart">
        <button
          class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block"
          @click="goToHelp"
        >
          <i class="fa fa-question"></i>
        </button>
        <button
          class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block"
          @click="goToHelp"
          data-bs-toggle="dropdown"
          data-bs-display="static"
          aria-expanded="false"
        >
          <i class="fa fa-user"></i>
        </button>
        <ul class="dropdown-menu" style="width: 231px; height: 135px">
          <li>
            <p class="ms-3 title-design">Usuario</p>
            <a
              class="align-sesion profile-dropdown hand link-secondary text-decoration-none row"
            >
              <button class="btn-circle-icon col-1 transparent">
                <i
                  class="fa fa-user col-1 style-button-icon"
                  style="font-size: 27px"
                ></i>
              </button>
              <p
                v-if="usuario"
                class="col"
                style="font-size: 14px; color: #5e6c84"
              >
                <small>{{ usuario.user }}</small>
              </p>
            </a>
          </li>
          <li>
            <a
              @click="cambiarPassword"
              class="align-sesion profile-dropdown hand link-primary row"
            >
              <i class="col-1" style="margin-left: 25px"></i>
              <p
                class="col"
                style="font-size: 14px; color: blue; margin-top: 7px"
              >
                <small>Cambiar contraseña</small>
              </p>
            </a>
          </li>
          <el-divider class="divider-style"></el-divider>
          <li>
            <a
              @click="cerrarSesion"
              class="align-sesion profile-dropdown link-secondary text-decoration-none hand row"
            >
              <i class="nc-icon nc-button-power col-1"></i>
              <p class="col" style="font-size: 14px">
                <small>Cerrar Sesión</small>
              </p>
            </a>
          </li>
        </ul>
      </div>
      <changePassword
        v-if="showChangePassword"
        @hide="showChangePassword = !showChangePassword"
        @guardado="cambiarPassword"
      />
    </div>
  </nav>
</template>
<script>
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import changePassword from "src/pages/Views/Franquicia/CambiarContraseña.vue";
export default {
  components: {
    [CollapseTransition.name]: CollapseTransition,
    changePassword,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      usuario: null,
      isClosed: true,
      showChangePassword: false,
    };
  },
  mounted() {
    this.minimizeSidebar();
    this.usuario = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    abrirSideBar() {
      this.$sidebar.toggleMinimize();
    },
    minimizeSidebar() {
      this.$sidebar.ocultarSideBar();
    },
    cerrarSesion() {
      localStorage.removeItem("user");
      localStorage.removeItem("empleado");
      this.$router.push({ name: "Login" });
    },
    showHideModalChangePassword() {
      this.showChangePassword = !this.showChangePassword;
    },
    cambiarPassword() {
      this.showHideModalChangePassword();
    },
    goToHelp() {
      this.$router.push({ name: "Ayuda" });
    },
  },
};
</script>
<style>
.style-button-icon {
  margin-right: 5px !important;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 100%;
  right: 0;
}

.transparent {
  background-color: transparent;
}

.btn-circle:hover {
  background-color: #888888;
}

.btn-circle-icon {
  margin-left: 10px;
  color: #888888;
  border: 1px solid #888888;
  width: 43px;
  height: 43px;
  padding: 6px 0px;
  border-radius: 30px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.align-sesion {
  height: 12px;
  margin-left: 5px;
}

.title-design {
  color: #5e6c84;
  font-size: 14px;
}

.divider-style {
  margin-bottom: 8px !important;
}
</style>
