import { rmaService } from "@/_services";

export const rma = {
  namespaced: true,
  state: {
    list: {
      data: {},
      status: {},
    },
    send: {
      data: {},
      status: {},
    },
    entity: {
      data: {},
      status: {},
    },
    finalizar: {
      data: {},
      status: {},
    },
    cadRev: {
      data: {},
      status: {},
    },
    generico: {
      data: {},
      status: {},
    },
    rmaMasivos: {
      data: {},
      status: {}
    },
    download: {
      data: {},
      status: {}
    },
    ficha: {
      data: {},
      status: {}
    },
  },
  mutations: {
    getRmaDownloadRequest(state) {
      state.download.status = {
        downloading: true,
      };
    },
    getRmaDownloadError(state, error) {
      state.download.data = {};
      state.download.status = {
        error,
      };
    },
    getRmaDownloadSuccess(state, response) {
      state.download.data = response;
      state.download.status = {
        downloaded: true,
      };
    },
    getRmaMasivosRequest(state) {
      state.rmaMasivos.status = {
        loading: true,
      };
    },
    getRmaMasivosError(state, error) {
      state.rmaMasivos.data = [];
      state.rmaMasivos.status = {
        error,
      };
    },
    getRmaMasivosSuccess(state, cp) {
      state.rmaMasivos.data = cp;
      state.rmaMasivos.status = {
        loaded: true,
      };
    },
    getRmasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getRmasError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getRmasSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    sendRmaRequest(state) {
      state.send.status = {
        creating: true,
      };
    },
    sendRmaSuccess(state, response) {
      state.send.data = response;
      state.send.status = {
        created: true,
      };
    },
    sendRmaError(state, error) {
      state.send.data = [];
      state.send.status = {
        error,
      };
    },
    getRMAByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getRMAByIdError(state, error) {
      state.entity.data = [];
      state.entity.status = {
        error,
      };
    },
    getRMAByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
    finalizarRMARequest(state) {
      state.finalizar.status = {
        finishing: true,
      };
    },
    finalizarRMASuccess(state, response) {
      state.finalizar.data = response;
      state.finalizar.status = {
        finished: true,
      };
    },
    finalizarRMAError(state, error) {
      state.finalizar.data = [];
      state.finalizar.status = {
        error,
      };
    },
    caducarRevertirRmaRequest(state) {
      state.cadRev.status = {
        loading: true,
      };
    },
    caducarRevertirRmaSuccess(state, env) {
      state.cadRev.data = env;
      state.cadRev.status = {
        loaded: true,
      };
    },
    caducarRevertirRmaError(state, error) {
      state.cadRev.data = null;
      state.cadRev.status = {
        error,
      };
    },
    generateRMAGenericoRequest(state) {
      state.generico.status = {
        generating: true,
      };
    },
    generateRMAGenericoSuccess(state, res) {
      state.generico.data = res;
      state.generico.status = {
        generated: true,
      };
    },
    generateRMAGenericoError(state, error) {
      state.generico.data = null;
      state.generico.status = {
        error,
      };
    },
    getClienteRMAGenericoRequest(state) {
      state.ficha.status = {
        loading: true,
      };
    },
    getClienteRMAGenericoSuccess(state, res) {
      state.ficha.data = res;
      state.ficha.status = {
        loaded: true,
      };
    },
    getClienteRMAGenericoError(state, error) {
      state.ficha.data = null;
      state.ficha.status = {
        error,
      };
    },
  },
  actions: {
    getRmaDownload({ commit }, idRma) {
      commit('getRmaDownloadRequest');
      rmaService
        .getRmaDownload(idRma)
        .then((response) => {
          commit('getRmaDownloadSuccess', response);
        })
        .catch((error) => {
          commit('getRmaDownloadError', error);
        });
    },
    getRmaMasivos({ commit }, req) {
      commit('getRmaMasivosRequest');
      rmaService
        .getRmaMasivos(req.pagination, req.filters)
        .then((response) => {
          commit('getRmaMasivosSuccess', response);
        })
        .catch((error) => {
          commit('getRmaMasivosError', error);
        });
    },
    getRmas({ commit }, req) {
      commit("getRmasRequest");
      rmaService
        .getRmas(req.pagination, req.body)
        .then((response) => {
          commit("getRmasSuccess", response);
        })
        .catch((error) => {
          commit("getRmasError", error);
        });
    },
    sendEnvioRma({ commit }, body) {
      commit("sendRmaRequest");
      rmaService
        .sendEnvioRma(body)
        .then((response) => {
          commit("sendRmaSuccess", response);
        })
        .catch((error) => {
          commit("sendRmaError", error);
        });
    },
    getRMAById({ commit }, req) {
      commit("getRMAByIdRequest");
      rmaService
        .getRMAById(req.id, req.token, req.type)
        .then((response) => {
          commit("getRMAByIdSuccess", response);
        })
        .catch((error) => {
          commit("getRMAByIdError", error);
        });
    },
    finalizarRMAById({ commit }, req) {
      commit("finalizarRMARequest");
      rmaService
        .finalizarRMAById(req.id, req.token, req.body, req.type)
        .then((response) => {
          commit("finalizarRMASuccess", response);
        })
        .catch((error) => {
          commit("finalizarRMAError", error);
        });
    },
    caducarRevertirRma({ commit }, req) {
      commit("caducarRevertirRmaRequest");
      rmaService
        .caducarRevertirRma(req.idRma, req.body)
        .then((rma) => {
          commit("caducarRevertirRmaSuccess", rma);
        })
        .catch((error) => {
          commit("caducarRevertirRmaError", error);
        });
    },
    generateRMAGenerico({ commit }, req) {
      commit("generateRMAGenericoRequest");
      rmaService
        .generateRMAGenerico(req.cliente, req.token, req.type)
        .then((rma) => {
          commit("generateRMAGenericoSuccess", rma);
        })
        .catch((error) => {
          commit("generateRMAGenericoError", error);
        });
    },
    getClienteRMAGenerico({ commit }, req) {
      commit("getClienteRMAGenericoRequest");
      rmaService
        .getClienteRMAGenerico(req.cliente, req.token, req.type)
        .then((rma) => {
          commit("getClienteRMAGenericoSuccess", rma);
        })
        .catch((error) => {
          commit("getClienteRMAGenericoError", error);
        });
    },
  },
};
