import { createAxios } from './axiosHttpMarconi';
export const paradaService = { getParada, estadosParada }

function getParada(paradaId) {
  const axios = createAxios();
  let urlPath = `/parada/${paradaId}`;
  return axios.get(urlPath);
}

function estadosParada(idParada){
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/parada/${idParada}/estados`;
  return axios.get(urlPath)
}