import { libretaContactoService } from '@/_services';

export const libretaContacto = {
  namespaced: true,
  state: {
    nuevo: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    contacto: {
      data: [],
      status: {},
    },
    list: {
      data: [],
      status: {},
    },
    delete: {
      data: null,
      status: {}
    },
  },
  mutations: {
    newContactoDireccionRequest(state) {
      state.nuevo.status = {
        loading: true,
      };
    },
    newContactoDireccionSuccess(state, env) {
      state.nuevo.data = env;
      state.nuevo.status = {
        loaded: true,
      };
    },
    newContactoDireccionError(state, error){
      state.nuevo.data = null
      state.nuevo.status={
        error
      }
    },
    updateContactoDireccionRequest(state) {
      state.update.status = {
        loading: true,
      };
    },
    updateContactoDireccionSuccess(state, env) {
      state.update.data = env;
      state.update.status = {
        loaded: true,
      };
    },
    updateContactoDireccionError(state, error){
      state.update.data = null
      state.update.status={
        error
      }
    },
    getContactoDireccionRequest(state) {
      state.contacto.status = {
        loading: true,
      };
    },
    getContactoDireccionSuccess(state, env) {
      state.contacto.data = env;
      state.contacto.status = {
        loaded: true,
      };
    },
    getContactoDireccionError(state, error){
      state.contacto.data = null
      state.contacto.status={
        error
      }
    },
    getAllContactosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getAllContactosSuccess(state, env) {
      state.list.data = env;
      state.list.status = {
        loaded: true,
      };
    },
    getAllContactoError(state, error){
      state.list.data = null
      state.list.status={
        error
      }
    },
    eliminarContactoDireccionRequest(state) {
      state.delete.status = {
        loading: true,
      };
    },
    eliminarContactoDireccionSuccess(state, direccion) {
      state.delete.data = direccion;
      state.delete.status = {
        loaded: true,
      };
    },
    eliminarContactoDireccionError(state, error) {
      state.delete.data = [];
      state.delete.status = {
        error,
      };
    },
  },
  actions: {
    newContactoDireccion({ commit }, nuevo) {
      commit('newContactoDireccionRequest');
      libretaContactoService.newContactoDireccion(nuevo.body)
        .then((contact) => {
          commit('newContactoDireccionSuccess', contact);
        })
        .catch((error) => {
          commit('newContactoDireccionError', error);
        });
    },
    updateContactoDireccion({ commit }, update) {
      commit('updateContactoDireccionRequest');
      libretaContactoService.updateContactoDireccion(update.contactoId,update.body)
        .then((contact) => {
          commit('updateContactoDireccionSuccess', contact);
        })
        .catch((error) => {
          commit('updateContactoDireccionError', error);
        });
    },
    getContactoDireccion({ commit }, clienteYcontacto) {
      commit('getContactoDireccionRequest');
      libretaContactoService.getContactoDireccion(clienteYcontacto.contactoId)
        .then((contact) => {
          commit('getContactoDireccionSuccess', contact);
        })
        .catch((error) => {
          commit('getContactoDireccionError', error);
        });
    },
    getAllContactos({ commit }, body ) {
      commit('getAllContactosRequest');
      libretaContactoService.getAllContactos(body)
        .then((contact) => {
          commit('getAllContactosSuccess', contact);
        })
        .catch((error) => {
          commit('getAllContactosError', error);
        });
    },
    eliminarContactoDireccion({ commit }, body) {
      commit('eliminarContactoDireccionRequest');
      libretaContactoService
        .eliminarContactoDireccion(body)
        .then((cliente) => {
          commit('eliminarContactoDireccionSuccess', cliente);
        })
        .catch((error) => {
          commit('eliminarContactoDireccionError', error);
        });
    }
  },
};