<template>
  <div class="container">
    <div class="clearfix">
      <div class="float-start">
        <b>
          <span v-if="isNew()" class="badge rounded-pill text-bg-danger"
            >Nuevo</span
          >
          {{ notification.nombre }}
        </b>
      </div>
      <div class="float-end">
        <el-link
          v-if="notification.adjunto"
          class="float-end"
          icon="el-icon-download"
          type="primary"
          :underline="false"
          @click="downloadFile"
          >Descargar fichero</el-link
        >
      </div>
    </div>
    <div class="row">
      <p class="mb-0">{{ notification.texto }}</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    downloadFile() {
      this.$store.dispatch(
        "dashboard/getNotificationFile",
        this.notification._id
      );
    },
    isNew() {
      const creado = moment(this.notification.createdAt).format("YYYY-MM-DD");
      const hoy = moment().format("YYYY-MM-DD");
      return creado === hoy;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>