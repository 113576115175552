<template>
  <div class="hk-direccion">
    <el-select v-model="value" placeholder="Seleccion una direccion">
      <el-option
        v-for="item in direcciones"
        :key="item._id"
        :label="item.label"
        :value="item._id">
        <span style="float: left">{{ item.label }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'selector-direccion',
  components: {
  },
  props: {
    type: {
      type: String,
      default: 'reecogida',
      description: 'Tipo de direcciones'
    },
  },
  data() {
    return {
      direcciones: [],
    };
  },
  methods: {
  }  
}
</script>

<style>

</style>