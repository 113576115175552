import { paradaService } from '@/_services';

export const parada = {
  namespaced: true,
  state: {
    paradaId:{
      data: null,
      status: {}
    },
    estados:{
      data: null,
      status: {}
    }
  },
  mutations: {
    getParadaInfoRequest(state) {
      state.paradaId.status = {
        loading: true,
      };
    },
    getParadaInfoError(state, error) {
      state.paradaId.data = [];
      state.paradaId.status = {
        error,
      };
    },
    getParadaInfoSuccess(state, response) {
      state.paradaId.data = response;
      state.paradaId.status = {
        loaded: true,
      };
    },
    getEstadosParadaRequest(state) {
      state.estados.status = {
        loading: true,
      };
    },
    getEstadosParadaError(state, error) {
      state.estados.data = [];
      state.estados.status = {
        error,
      };
    },
    getEstadosParadaSuccess(state, response) {
      state.estados.data = response;
      state.estados.status = {
        loaded: true,
      };
    },

  },
  actions: {
    getParadaInfo({ commit }, masivoId) {
      commit('getParadaInfoRequest');
      paradaService
        .getParada(masivoId)
        .then((response) => {
          commit('getParadaInfoSuccess', response);
        })
        .catch((error) => {
          commit('getParadaInfoError', error);
        });
    },
    getEstadosParada({ commit }, idParada) {
      commit('getEstadosParadaRequest');
      paradaService.estadosParada(idParada)
        .then((response) => {
          commit('getEstadosParadaSuccess', response);
        }).catch((error) => {
          commit('getEstadosParadaError', error);
        });
    },
  },
};