import { createAxios, createAxiosHeaders } from "./axiosHttpMarconi";
export const dashboardService = {
  getDashboard,
  getNotificationFile,
};

function getDashboard() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/dashboard`;
  return axios.get(urlPath);
}

function getNotificationFile(notificationId) {
  const axios = createAxiosHeaders();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/cliente/${user.extraData.clienteId}/notification/${notificationId}/file`;
  return axios
    .get(urlPath)
    .then((response) => {
      const byteCharacters = atob(response.data);
      const byteArrays = [];
      for (let i = 0; i < byteCharacters.length; i++)
        byteArrays.push(byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteArrays);
      const type = response.headers["content-type"];
      return {
        file: new Blob([byteArray], { type }),
        filename: response.headers["content-disposition"],
      };
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}
