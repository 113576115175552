import createAxios from './axiosHttpMarconi';
export const serviciosService = {
  getServicios,getServiciosByCliente
};

function getServicios(tipoServicio) {
  const axios = createAxios();
  // const tipo = localStorage.getItem('tipo');
  let urlPath = `servicio?sort=_id&order=ascending`; 
  if (tipoServicio && tipoServicio != "") {
    urlPath += `&tipo=${tipoServicio}`;
  }
  return axios.get(urlPath);
}
function getServiciosByCliente(request) {
  const axios = createAxios();
  let urlPath = `/servicio/cliente/${request.id}`;
  if (request.tipo && request.tipo != "") {
    urlPath += `?tipo=${request.tipo}`;
  }
  if (request.rma && request.rma != "") {
    urlPath += `&rma=${request.rma}`;
  }
  return axios.get(urlPath);
}