<template>
  <li class="infinite-list-item" v-on:click="selectedItem">{{ label }}</li>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      default: () => ""
    },
    value: {
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
    };
  },
  mounted() {

  },
  computed: {
  },
  methods: {
    selectedItem(){
      this.$parent.selected(this.value, this.label);
      this.$parent.clickToggle();
    }
  },
  watch: {
  },
};
</script>

<style scoped>
li:hover{
  background-color: #f5f7fa;
}
</style>