import { createAxios, createAxiosV2 } from "./axiosHttpMarconi";

function sendEnvio(body) {
  const axios = createAxios();
  const urlPath = "envio";
  return axios.post(urlPath, body);
}

function getEnviosAsExcel(filters) {
  const axios = createAxios();
  const cliente = JSON.parse(localStorage.getItem("cliente"));
  let urlPath = `/cliente/${cliente._id}/envio/excel?limit=2000`;

  urlPath += getFilters(filters);
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getEnviosAsFileAmazon(filters) {
  const axios = createAxios();
  const cliente = JSON.parse(localStorage.getItem("cliente"));
  let urlPath = `/cliente/${cliente._id}/envio/file-amazon?limit=2000`;

  urlPath += getFilters(filters);
  return axios
    .get(urlPath, {
      responseType: "blob",
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

function getAllEnvios(pagination, filters) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `cliente/${user.extraData.clienteId}/envio`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += getFilters(filters);
  return axios.get(urlPath);
}

function getEnvio(envioId) {
  const axios = createAxios();
  let urlPath = `envio/${envioId}`;
  return axios.get(urlPath);
}

function getDirHabituales(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `cliente/${user.extraData.clienteId}/direcciones`;
  if (body.page != null) {
    urlPath += `?page=${body.page}&limit=${body.limit}`;
  } else {
    urlPath += "?";
  }
  if (body.tipo) {
    urlPath += `&tipo=${body.tipo}`;
  }
  if (body.normalizada) {
    urlPath += `&normalizada=${body.normalizada}`;
  }
  if (body.defecto) {
    urlPath += `&defecto=${body.defecto}`;
  }
  if (body.sort) {
    urlPath += `&sort=${body.sort}`;
  } else urlPath += `&sort=_id`;
  if (body.order) {
    urlPath += `&order=${body.order}`;
  } else urlPath += `&order=ascending`;
  return axios.get(urlPath);
}

function getManifiesto(request) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `cliente/${user.extraData.clienteId}/manifiesto?inicio=${request.fechaInicio}&fin=${request.fechaFin}&propios=${request.propios}`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getEstadosEnvio(envioId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = "";
  if (user && user.extraData && user.extraData.clienteId)
    urlPath = `cliente/${user.extraData.clienteId}/envio/${envioId}/estados`;
  else urlPath = `/envio/${envioId}/estados`;

  return axios.get(urlPath);
}

function getEnvioSeguimiento(filter) {
  const axios = createAxiosV2();
  let urlPath = "seguimiento";
  if (filter && filter.envioId) {
    urlPath += `/${filter.envioId}`;
  }
  if (filter && filter.clienteId && filter.localizador) {
    urlPath += `/cliente/${filter.clienteId}/envio?localizador_eq=${filter.localizador}`;
  }
  if (filter && filter.referencia && filter.clienteId) {
    urlPath += `/cliente/${filter.clienteId}/envio?referencia_eq=${filter.referencia}`;
  }
  return axios.get(urlPath);
}

function modificarEstado(envioId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `cliente/${user.extraData.clienteId}/envio/${envioId}/estados`;
  let body = { codigo: "19", tipo: "ESTADO" };
  return axios.post(urlPath, body);
}

function getJustificante(request) {
  const axios = createAxiosV2();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `cliente/${user.extraData.clienteId}/envio/${request._id}/comprobante-entrega`;
  return axios.get(urlPath).then((response) => {
    if (response instanceof Object) return JSON.stringify(response);
    else {
      const byteCharacters = atob(response);
      const byteArrays = [];
      for (let i = 0; i < byteCharacters.length; i++)
        byteArrays.push(byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteArrays);
      return new Blob([byteArray], { type: "application/pdf" });
    }
  });
}

function envioEtiqueta(body) {
  const axios = createAxiosV2();
  const franquicia = JSON.parse(localStorage.getItem("user"));
  const urlPath = `franquicia/${franquicia.franquiciaId}/envio/etiqueta`;
  return axios.post(urlPath, body, {
    responseType: "blob",
  });
}

function getFilters(filters) {
  let urlPath = "";
  if (filters && filters.fecha) {
    urlPath += `&dateStart=${filters.fecha[0]}`;
    urlPath += `&dateEnd=${filters.fecha[1]}`;
  }
  if (filters && filters.servicios) {
    urlPath += `&servicios=${filters.servicios.join()}`;
  }
  if (filters && filters.estados) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.prop}&order=${filters.sort.order}`;
  }
  if (filters && filters.localizador) {
    urlPath += `&localizador=${filters.localizador}`;
  }
  if (filters && filters.referencia) {
    urlPath += `&referencia=${filters.referencia}`;
  }
  if (filters.origenEnvioProgramado) {
    urlPath += "&app=PLANTILLA";
  }
  if (filters.origenRma) {
    urlPath += "&app=RMA";
  }
  if (filters && filters.tipo_devolucion) {
    urlPath += `&tipo_devolucion=${filters.tipo_devolucion}`;
  }
  if (filters && filters.reembolso) {
    urlPath += `&reembolso=${filters.reembolso}`;
  }
  if (filters && filters.destinatario) {
    urlPath += `&destinatario=${filters.destinatario}`;
  }
  return urlPath;
}

function logisticaInversa(body) {
  const axios = createAxios();
  const cliente = JSON.parse(localStorage.getItem("cliente"));
  const urlPath = `/cliente/${cliente._id}/envio/${body._id}/invertir`;
  return axios.post(urlPath, body);
}

export const envioService = {
  getAllEnvios,
  sendEnvio,
  getDirHabituales,
  getEnvio,
  getManifiesto,
  getEstadosEnvio,
  getEnvioSeguimiento,
  modificarEstado,
  getJustificante,
  envioEtiqueta,
  getEnviosAsExcel,
  logisticaInversa,
  getEnviosAsFileAmazon,
};
