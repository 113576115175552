import { clienteService} from '@/_services';
export const cliente = {
  namespaced: true,
  state: {
    entity: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getClienteRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getClienteError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getClienteSuccess(state, body) {
      state.entity.data = body;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getCliente({ commit }) {
      commit('getClienteRequest');
      clienteService.getCliente()
        .then((cliente) => {
          commit('getClienteSuccess', cliente);
        })
        .catch((error) => {
          commit('getClienteError', error);
        });
    },
  },
};