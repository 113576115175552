import createAxios from './axiosHttpMarconi';
export const codigoPostalService = {
  getCodigosPostales
};

function getCodigosPostales(filters) {
  const axios = createAxios();
  let urlPath = 'codigopostal';
  if (filters && filters.codigoPostal) {
    urlPath += `?codigoPostal=${filters.codigoPostal}`;
  }
  return axios.get(urlPath);
}