import { puntoRedService } from "@/_services";
export const puntoRed = {
  namespaced: true,
  state: {
    puntos: {
      data: [],
      status: {},
    },
    busqueda: {
      data: {},
      status: {},
    },
    excel: {
      data: {},
      status: {},
    },
  },
  mutations: {
    puntoRedExcelRequest(state) {
      state.excel.status = {
        loading: true,
      };
    },
    puntoRedExcelSuccess(state, pdf) {
      state.excel.data = pdf;
      state.excel.status = {
        loaded: true,
      };
    },
    puntoRedExcelError(state, error) {
      state.excel.data = null;
      state.excel.status = {
        error,
      };
    },
    getPuntosRedByPostalCodeRequest(state) {
      state.puntos.status = {
        loading: true,
      };
    },
    getPuntosRedByPostalCodeError(state, error) {
      state.puntos.data = null;
      state.puntos.status = {
        error,
      };
    },
    getPuntosRedByPostalCodeSuccess(state, list) {
      state.puntos.data = list;
      state.puntos.status = {
        loaded: true,
      };
    },
    busquedaOficinasRequest(state) {
      state.busqueda.status = {
        loading: true,
      };
    },
    busquedaOficinasError(state, error) {
      state.busqueda.data = null;
      state.busqueda.status = {
        error,
      };
    },
    busquedaOficinasSuccess(state, result) {
      state.busqueda.data = result;
      state.busqueda.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getPuntosAsExcel({ commit }, request) {
      commit("puntoRedExcelRequest");
      puntoRedService
        .getPuntosAsExcel(request)
        .then((excel) => {
          commit("puntoRedExcelSuccess", excel);
        })
        .catch((error) => {
          commit("puntoRedExcelError", error);
        });
    },
    getPuntosRedByPostalCode({ commit }, req) {
      commit("getPuntosRedByPostalCodeRequest");
      puntoRedService
        .getPuntosRedByPostalCode(req)
        .then((list) => {
          commit("getPuntosRedByPostalCodeSuccess", list);
        })
        .catch((error) => {
          commit("getPuntosRedByPostalCodeError", error);
        });
    },
    busquedaOficinas({ commit }, req) {
      commit("busquedaOficinasRequest");
      puntoRedService
        .busquedaOficinas(req.pagination, req.request)
        .then((env) => {
          commit("busquedaOficinasSuccess", env);
        })
        .catch((error) => {
          commit("busquedaOficinasError", error);
        });
    },
  },
};
