import {
  codigoPostalService
} from '@/_services';

export const codigoPostal = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getCodigoPostalRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelCodigoPostalRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getCodigoPostalSuccess(state, cp) {
      state.list.data = cp;
      state.list.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getCodigosPostales({ commit }, filters) {
      commit('getCodigoPostalRequest');
      codigoPostalService
        .getCodigosPostales(filters)
        .then((cp) => {
          commit('getCodigoPostalSuccess', cp);
        })
        .catch((error) => {
          commit('cancelCodigoPostalRequest', error);
        });
    },
  },
};